button {
    background-color: #FFFFFF;
    color: #BB0000;
    border: none;
    font-size: 24px;
    font-weight: bold;
}

.button:hover {
    cursor: pointer; 
}

.button:focus,
.button:active {
    background-color: white;
    transition: none;
}